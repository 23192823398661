var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flood"},[(!_vm.currentView)?_c('div',[_c('filter-levels',{attrs:{"filter-type":"flood","extra-menu-items":[_vm.ExtraSettingsMenuItems.AdvancedSettings, _vm.ExtraSettingsMenuItems.CommonRules, _vm.ExtraSettingsMenuItems.VirtualNew]},on:{"changeCurrentView":_vm.handleCurrentView}}),_c('div',{staticClass:"main-filter-settings"},[_c('a-card',{staticClass:"mt-base",attrs:{"title":_vm.$t('field_common_settings_title')}},[_c('common-settings',{attrs:{"filter-type":"flood","store-path":"$store.state.chatState.configCopy"}})],1)],1)],1):_c('div',[(_vm.currentView === _vm.ExtraSettingsMenuItems.AdvancedSettings)?_c('div',[_c('section-wrapper',{attrs:{"section-title":"advanced_settings"},on:{"onNavBack":_vm.handleNavBack},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('div',{ref:"flood_min_message_interval"},[_c('number-input',{attrs:{"setup":{
                'func': _vm.configInputSetup,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_min_message_interval',
                  'min': 0,
                  'max': Number.MAX_SAFE_INTEGER,
                  'hasAnchor': true
                }
              }}}),_c('a-divider')],1),_c('number-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'flood_message_count',
                'min': 0,
                'max': Number.MAX_SAFE_INTEGER,
                'hasAnchor': true
              }
            }}}),_c('number-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'flood_message_interval',
                'min': 0,
                'max': Number.MAX_SAFE_INTEGER,
                'hasAnchor': true
              }
            }}}),_c('a-divider'),_c('number-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'flood_duplicate_words_multiply',
                'min': 0,
                'max': 100,
                'hasAnchor': true
              }
            }}}),_c('checkbox-input',{attrs:{"setup":{
              'func': _vm.checkboxInputSetter,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'flood_all_global',
                'hasAnchor': true
              }
            }}}),(!_vm.showFloodAllGlobal)?_c('nested-content',[_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_global_text',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_global_audio',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_global_document',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_global_sticker',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_global_photo',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_global_video',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_global_animation',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_global_contact',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_global_voice',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_global_videonote',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_global_poll',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_global_dice',
                  'hasAnchor': true
                }
              }}})],1):_vm._e(),_c('checkbox-input',{attrs:{"setup":{
              'func': _vm.checkboxInputSetter,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'flood_all_user',
                'hasAnchor': true
              }
            }}}),(!_vm.showFloodAllUser)?_c('nested-content',[_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_user_text',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_user_audio',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_user_document',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_user_sticker',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_user_photo',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_user_video',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_user_animation',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_user_contact',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_user_voice',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_user_videonote',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_user_poll',
                  'hasAnchor': true
                }
              }}}),_c('checkbox-input',{attrs:{"setup":{
                'func': _vm.checkboxInputSetter,
                'args': {
                  'model': _vm.$store.state.chatState.configCopy,
                  'key': 'flood_remove_if_prev_user_dice',
                  'hasAnchor': true
                }
              }}})],1):_vm._e()]},proxy:true}],null,false,3778942924)})],1):_vm._e(),(_vm.currentView === _vm.ExtraSettingsMenuItems.VirtualNew)?_c('div',[_c('section-wrapper',{attrs:{"section-title":"virtual_new"},on:{"onNavBack":_vm.handleNavBack},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('virtual-new-settings',{attrs:{"filter-type":"flood","store-path":"$store.state.chatState.configCopy","pick-fields":[_vm.VirtualNewFields.New_limit, _vm.VirtualNewFields.New_limit_msg_count]}})]},proxy:true}],null,false,1844386096)})],1):_vm._e(),(_vm.currentView === _vm.ExtraSettingsMenuItems.CommonRules)?_c('div',[_c('section-wrapper',{attrs:{"section-title":"common_rules"},on:{"onNavBack":_vm.handleNavBack},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('common-rules-settings',{attrs:{"filter-type":"flood","store-path":"$store.state.chatState.configCopy"}})]},proxy:true}],null,false,2599499958)})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }