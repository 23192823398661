






























































































































































































































































































































































































import {
  CommonRulesFields,
  ExtraSettingsMenuItems,
  BaseSpecialSettingFields, StrictModeFields
} from '@/views/chat/filters/FilterTypes'
import CommonRulesSettings from '@/views/chat/filters/components/SettingGroups/CommonRulesSettings.vue'
import VirtualNewSettings from '@/views/chat/filters/components/Fields/VirtualNewSettings.vue'
import CommonSettings from '@/views/chat/filters/components/SettingGroups/CommonSettings.vue'
import FilterLevels from '@/views/chat/filters/components/FilterLevels.vue'
import { VirtualNewFields } from '@/views/chat/filters/FilterTypes'
import SectionWrapper from '@/views/chat/filters/components/SectionWrapper.vue'
import FilterViewMixin from '@/views/chat/filters/include/FilterViewMixin'
import { InputSetups } from '@/mixins/input-setups'
import chatConfig from '@/mixins/chatConfig'
import IgnoreCaption from '@/views/chat/filters/components/Fields/IgnoreCaption.vue'
import StrictMode from '@/views/chat/filters/components/Fields/StrictMode.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { FieldData } from 'piramis-base-components/src/components/Pi/types'

import { Mixins, Ref, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'

@Component({
  components: {
    IgnoreCaption,
    CommonRulesSettings,
    VirtualNewSettings,
    CommonSettings,
    FilterLevels,
    StrictMode,
    SectionWrapper
  },
  data() {
    return {
      ExtraSettingsMenuItems,
      CommonRulesFields,
      VirtualNewFields,
      BaseSpecialSettingFields,
      StrictModeFields
    }
  },
  mixins: [ chatConfig ]
})
export default class FloodView extends Mixins<UseFields, InputSetups, FilterViewMixin>(UseFields, InputSetups, FilterViewMixin) {

  @Ref('flood_min_message_interval') floodMinMessageIntervalRef!: HTMLDivElement

  floodUser = [
    'flood_remove_if_prev_user_animation',
    'flood_remove_if_prev_user_audio',
    'flood_remove_if_prev_user_contact',
    'flood_remove_if_prev_user_dice',
    'flood_remove_if_prev_user_document',
    'flood_remove_if_prev_user_photo',
    'flood_remove_if_prev_user_poll',
    'flood_remove_if_prev_user_sticker',
    'flood_remove_if_prev_user_text',
    'flood_remove_if_prev_user_video',
    'flood_remove_if_prev_user_videonote',
    'flood_remove_if_prev_user_voice' ]

  floodAll = [
    'flood_remove_if_prev_global_text',
    'flood_remove_if_prev_global_audio',
    'flood_remove_if_prev_global_document',
    'flood_remove_if_prev_global_sticker',
    'flood_remove_if_prev_global_photo',
    'flood_remove_if_prev_global_video',
    'flood_remove_if_prev_global_animation',
    'flood_remove_if_prev_global_contact',
    'flood_remove_if_prev_global_voice',
    'flood_remove_if_prev_global_videonote',
    'flood_remove_if_prev_global_poll',
    'flood_remove_if_prev_global_dice'
  ]

  get showFloodAllGlobal(): boolean {
    return this.$store.state.chatState.configCopy.flood_all_global
  }

  get showFloodAllUser(): boolean {
    return this.$store.state.chatState.configCopy.flood_all_user
  }

  checkboxInputSetter(args: FieldData): FieldData {
    args.setter = (value: boolean) => {
      this.$store.commit('EXEC_CALLBACK_IN_STORE', {
        callback: () => {
          if (args.key === 'flood_all_user') {
            this.toggleFloodUser()
          } else if (args.key === 'flood_all_global') {
            this.toggleFloodAll()
          } else {
            args.model[args.key] = value
          }
        }
      })
    }
    return args
  }

  toggleFloodAll(): void {
    this.$set(this.$store.state.chatState.configCopy, 'flood_all_global', !(this.$store.state.chatState.configCopy as any).flood_all_global)

    this.floodAll.slice().forEach((filter) => {
      this.$set(this.$store.state.chatState.configCopy, filter, (this.$store.state.chatState.configCopy as any).flood_all_global)
    })
  }

  toggleFloodUser(): void {
    this.$set(this.$store.state.chatState.configCopy, 'flood_all_user', !(this.$store.state.chatState.configCopy as any).flood_all_user)

    this.floodUser.slice().forEach((filter) => {
      this.$set(this.$store.state.chatState.configCopy, filter, (this.$store.state.chatState.configCopy as any).flood_all_user)
    })
  }
  @Watch('currentView')
  onCurrentViewChange(newView:ExtraSettingsMenuItems):void {
    if (newView === ExtraSettingsMenuItems.AdvancedSettings) {
      this.$nextTick(() => {
        if (this.$store.state.chatState.configCopy.flood_min_message_interval === 0 && this.floodMinMessageIntervalRef) {
          this.floodMinMessageIntervalRef.style.display = 'none'
        }
      })
    }
  }

  created(): void {
    this.$store.commit('EXEC_CALLBACK_IN_STORE', {
      callback: () => {
        this.$set(this.$store.state.chatState.configCopy, 'flood_all_global', !!this.floodAll.every(value => this.$store.state.chatState.configCopy[value]))
        this.$set(this.$store.state.chatState.configCopy, 'flood_all_user', !!this.floodUser.every(value => this.$store.state.chatState.configCopy[value]))
      }
    })
  }

}
